import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import axios from 'axios';
import './App.css';

// Components
import Gallery from './Gallery'; // Ensure this file exists and is correctly exported

function App() {
  const [imageOfMonth, setImageOfMonth] = useState(null);

  useEffect(() => {
    fetchImageOfTheMonth();
  }, []);

  const fetchImageOfTheMonth = async () => {
    try {
      const response = await axios.get('https://strapi.komovc.si/api/images?populate=*');
      if (response.data && response.data.data) {
        // Find the image marked as "Image of the Month"
        const image = response.data.data.find(img => img.attributes.isImageOfTheMonth);
        setImageOfMonth(image);
      }
    } catch (error) {
      console.error('Error fetching Image of the Month:', error);
    }
  };

  return (
    <Router>
      <div>
        {/* Navigation Bar */}
        <nav className="navbar">
          <div className="logo-container">
            <Link to="/">
              <img src="https://i.e-z.host/slikca/zxosbpep.png" alt="Logo" className="logo" />
            </Link>
          </div>
          <ul className="nav-links">
            <li><Link to="/gallery">Gallery</Link></li>
          </ul>
        </nav>

        {/* Main content and Routing Setup */}
        <Routes>
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/projects" element={<div className="project-content">
            <h1>Projects</h1>
            <p>Details about my projects will go here.</p>
          </div>} />
          <Route path="/contact" element={<div className="contact-content">
            <h1>Contact</h1>
            <p>Here's how you can reach me: <a href="mailto:info@komovc.si">info@komovc.si</a></p>
          </div>} />
          <Route path="/" element={
            <div className="main-container">
              <div className="image-container">
                <img src="https://i.e-z.host/slikca/w7j7zyft.jpg" alt="Portrait of Jakob, known as Komovc" className="profile-image" />
              </div>
              <div className="about-container">
                <h1>About Me</h1>
                <p>Hi, I’m Jakob, known as Komovc. I am a photographer, graphic designer, and video editor. I try my best to make my work look as good as possible.</p>
              </div>

              {/* Divider */}
              <hr className="divider" />

              {/* Image of the Month Section under the Divider */}
              <div className="image-of-the-month">
                <h2>Image of the Month</h2>
                {imageOfMonth ? (
                  <a href={imageOfMonth.attributes.instagramLink} target="_blank" rel="noopener noreferrer">
                    <img 
                      src={`https://strapi.komovc.si${imageOfMonth.attributes.file.data.attributes.url}`} 
                      alt={imageOfMonth.attributes.title} 
                      className="month-image" 
                    />
                  </a>
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>} />
        </Routes>

        {/* Footer */}
        <footer className="footer">
          <p>Contact me at: <a href="mailto:info@komovc.si">info@komovc.si</a></p>
          <div className="social-media-links">
            <a href="https://www.instagram.com/komovc.si/" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
