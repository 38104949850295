import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Gallery.css'; // Ensure the CSS file is correctly linked

function Gallery() {
  const [images, setImages] = useState([]);
  const [selectedImg, setSelectedImg] = useState(null);
  const [page, setPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total number of pages state
  const pageSize = 25; // Number of images per page (adjust as needed)

  useEffect(() => {
    fetchGalleryImages(page); // Fetch images whenever the page changes
  }, [page]);

  const fetchGalleryImages = async (page) => {
    try {
      const response = await axios.get(
        `https://strapi.komovc.si/api/gallery-images?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}`
      );
      if (response.data && response.data.data) {
        setImages(response.data.data); // Update images state
        const totalItems = response.data.meta.pagination.total;
        setTotalPages(Math.ceil(totalItems / pageSize)); // Calculate total number of pages
      }
    } catch (error) {
      console.error('Error fetching gallery images:', error);
    }
  };

  // Function to handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page smoothly
  };

  return (
    <div className="gallery-container">
      {images.map((image, index) => (
        <div
          key={index}
          className="gallery-item"
          onClick={() =>
            setSelectedImg(
              `https://strapi.komovc.si${image.attributes.file.data.attributes.url}`
            )
          }
        >
          <img
            src={`https://strapi.komovc.si${image.attributes.file.data.attributes.url}`}
            alt={`Gallery item ${index + 1}`}
          />
        </div>
      ))}

      {selectedImg && (
        <div className="backdrop" onClick={() => setSelectedImg(null)}>
          <img
            src={selectedImg}
            alt="Enlarged pic"
            className="enlarged-img"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}

      {/* Pagination Controls */}
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((num) => (
          <button
            key={num}
            className={`pagination-button ${num === page ? 'active' : ''}`}
            onClick={() => handlePageChange(num)}
          >
            {num}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Gallery;
